import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { createContext, useReducer } from 'react';
import { Video } from 'types/catalog.entity';

export interface VideoReducerState {
  // Necessary to compare changes
  original: Video;
  // Will be update in the reducer
  updated: Video;
  // Track difference between original & updated
  hasChange: boolean;
}

type InitStateAction = {
  type: 'initState';
  data: Video;
};

type UpdateBoostFactorAction = {
  type: 'updateBoostFactor';
  data: {
    boostFactor: number;
  };
};

export type VideoReducerAction = InitStateAction | UpdateBoostFactorAction;

export type VideoDispatcher = React.Dispatch<VideoReducerAction>;

const initialState: VideoReducerState = {
  original: undefined!,
  updated: undefined!,
  hasChange: false,
};

const stateUpdate = (state: VideoReducerState): VideoReducerState => {
  return {
    ...state,
    hasChange: !isEqual(state.original, state.updated),
  };
};

export function videoReducer(prevState: VideoReducerState, action: VideoReducerAction): VideoReducerState {
  switch (action.type) {
    case 'initState': {
      const video = (action as InitStateAction).data;
      if (!video.views.total) {
        video.views.total = 0;
      }
      return stateUpdate({
        ...prevState,
        original: cloneDeep(video),
        updated: video,
      });
    }
    case 'updateBoostFactor': {
      return stateUpdate({
        ...prevState,
        updated: { ...prevState.updated, boostFactor: action.data.boostFactor },
      });
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}

/**
 * Sharing offers state between multiple subcomponents
 */
export const VideoContext = createContext({} as { state: VideoReducerState; dispatch: VideoDispatcher });

/**
 * Wrapper to provide context to a component
 */
export function VideoProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(videoReducer, initialState);
  return (
    <VideoContext.Provider
      value={
        {
          state,
          dispatch,
        } as { state: VideoReducerState; dispatch: VideoDispatcher }
      }
    >
      {children}
    </VideoContext.Provider>
  );
}
