import axios from 'axios';
import { BynderApi, BynderBaseAPIUrl } from '../common/api-routes';

interface User {
  email: string;
}

let currentUserCache: User | undefined = undefined;

/**
 * Return current bynder connected user
 * Bynder as set Same-origin policy on is API
 * The request will always return 401
 */
export const getCurrentUser = async (): Promise<User> => {
  if (currentUserCache) {
    return currentUserCache;
  }
  return await axios
    .get(BynderBaseAPIUrl + BynderApi.currentUser)
    .then(({ data }: { data: User }) => {
      currentUserCache = data;
      return data;
    })
    .catch((e) => {
      console.error('Error from Bynder API', e);
      return { email: 'int-design@crakmedia.com' };
    });
};
