import { Login } from '@bynder/compact-view';
import { Toasts } from 'components/toasts';
import { ContextProviders } from 'contexts/providers';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import styled from 'styled-components';
import { BynderView } from 'views/bynder';

const PageStyled = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <>
        {/* <HeaderAdmin /> */}
        <Outlet />
      </>
    ),
    children: [
      {
        path: '/',
        element: <BynderView />,
      },
      // {
      //   path: '/models',
      //   element: <Navigate replace to="/catalog" />,
      // },
      // {
      //   path: '/catalog',
      //   element: <Navigate replace to="/catalog/performer" />,
      // },
      // {
      //   path: '/catalog/performer',
      //   element: <CatalogSearchView />,
      // },
      // {
      //   path: '/catalog/pn',
      //   element: <CatalogSearchView />,
      // },
      // {
      //   path: '/catalog/video',
      //   element: <CatalogSearchView />,
      // },
      // {
      //   path: '/catalog/performer/:slug',
      //   element: <ModelAdminView />,
      //   loader: fetchPerformer,
      //   errorElement: <ModelNotFound />,
      // },
      // {
      //   path: '/catalog/pn/:slug',
      //   element: <ModelAdminView />,
      //   loader: fetchPn,
      //   errorElement: <ModelNotFound />,
      // },
      // {
      //   path: '/video/:slug',
      //   element: <VideoAdminView />,
      //   loader: fetchVideo,
      //   errorElement: <VideoNotFound />,
      // },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
]);

function App() {
  return (
    <ContextProviders>
      <Login portal={{ url: 'www.aclmnop.com' }}>
        <PageStyled>
          <RouterProvider router={router} />
          <Toasts />
        </PageStyled>
      </Login>
    </ContextProviders>
  );
}

export default App;
