import { BynderAsset } from 'api/bynderAssets';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { createContext, useReducer } from 'react';
import { AdminAttributes } from 'types/adminAttributes.entity';
import { ItemType } from 'types/catalog.entity';

export interface AdminAttributesReducerState {
  // Entity id that is currently modified
  itemId: string;
  itemType: ItemType;
  // Necessary to compare changes
  original: AdminAttributes;
  // Will be update in the reducer
  updated: AdminAttributes;
  // Track difference between original & updated
  hasChange: boolean;
  totalWeight: number;
}

type InitStateAction = {
  type: 'initState';
  data: {
    itemId: string;
    itemType: ItemType;
    adminAttributes: AdminAttributes;
  };
};

type UpdateWeightAction = {
  type: 'updateWeight';
  data: {
    assestId: string;
    weight: number;
  };
};

type AddBynderAsset = {
  type: 'addBynderAsset';
  data: BynderAsset;
};

type RemoveCard = {
  type: 'removeCard';
  data: { id: string };
};

type ToggleInWeightedGridAction = {
  type: 'toggleInWeightedGrid';
  value?: boolean;
};

export type AdminAttributesReducerAction =
  | InitStateAction
  | UpdateWeightAction
  | AddBynderAsset
  | RemoveCard
  | ToggleInWeightedGridAction;

export type AdminAttributesDispatcher = React.Dispatch<AdminAttributesReducerAction>;

const initialState: AdminAttributesReducerState = {
  itemId: undefined!,
  itemType: undefined!,
  original: undefined!,
  updated: undefined!,
  hasChange: false,
  totalWeight: 100,
};

const stateUpdate = (state: AdminAttributesReducerState): AdminAttributesReducerState => {
  return {
    ...state,
    hasChange: !isEqual(state.original, state.updated),
    totalWeight: state.updated.thumbsVariant.reduce((total, variant) => total + variant.weight, 0),
  };
};

export function adminAttributesReducer(
  prevState: AdminAttributesReducerState,
  action: AdminAttributesReducerAction,
): AdminAttributesReducerState {
  switch (action.type) {
    case 'initState': {
      const data = (action as InitStateAction).data;
      if (!data.adminAttributes) {
        data.adminAttributes = {
          showInWeightedGrid: false,
          thumbsVariant: [],
        };
      }
      data.adminAttributes.thumbsVariant = data.adminAttributes.thumbsVariant.filter((t) => t.assetId);
      return stateUpdate({
        ...prevState,
        itemId: data.itemId,
        itemType: data.itemType,
        original: cloneDeep(data.adminAttributes),
        updated: data.adminAttributes,
      });
    }
    case 'updateWeight': {
      prevState.updated.thumbsVariant.forEach((v) => {
        if (v.assetId === action.data.assestId) {
          v.weight = action.data.weight;
        }
      });
      return stateUpdate({
        ...prevState,
      });
    }
    case 'addBynderAsset': {
      const thumbsVariant = prevState.updated.thumbsVariant;
      thumbsVariant.push({
        imgSrc: '',
        weight: 0,
        assetId: action.data.assetId,
      });
      return stateUpdate({
        ...prevState,
        updated: {
          ...prevState.updated,
          thumbsVariant: thumbsVariant,
        },
      });
    }
    case 'removeCard': {
      const thumbsVariant = prevState.updated.thumbsVariant;
      const i = thumbsVariant.findIndex((variant) => {
        return variant.assetId === action.data.id;
      });
      thumbsVariant.splice(i, 1);
      return stateUpdate({
        ...prevState,
        updated: {
          ...prevState.updated,
          thumbsVariant: thumbsVariant,
        },
      });
    }
    case 'toggleInWeightedGrid': {
      const currentShowInWeightedGrid = prevState.updated.showInWeightedGrid;
      const newShowInWeightedGrid = action.value !== undefined ? action.value : !currentShowInWeightedGrid;
      return stateUpdate({
        ...prevState,
        updated: {
          ...prevState.updated,
          showInWeightedGrid: newShowInWeightedGrid,
        },
      });
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}

/**
 * Sharing offers state between multiple subcomponents
 */
export const AdminAttributesContext = createContext(
  {} as { state: AdminAttributesReducerState; dispatch: AdminAttributesDispatcher },
);

/**
 * Wrapper to provide context to a component
 */
export function AdminAttributesProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(adminAttributesReducer, initialState);
  return (
    <AdminAttributesContext.Provider
      value={
        {
          state,
          dispatch,
        } as { state: AdminAttributesReducerState; dispatch: AdminAttributesDispatcher }
      }
    >
      {children}
    </AdminAttributesContext.Provider>
  );
}
