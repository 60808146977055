import { AdminAttributesProvider } from './adminAttributes';
import { ModelProvider } from './model';
import { NetworkCacheProvider } from './networkCache';
import { OffersProviders } from './offers';
import { ToastProvider } from './toasts';
import { VideoProvider } from './video';

export function ContextProviders({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <NetworkCacheProvider>
      <ToastProvider>
        <VideoProvider>
          <ModelProvider>
            <AdminAttributesProvider>
              <OffersProviders>{children}</OffersProviders>
            </AdminAttributesProvider>
          </ModelProvider>
        </VideoProvider>
      </ToastProvider>
    </NetworkCacheProvider>
  );
}
