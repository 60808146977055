import { AssetUsage, AssetWithUsage } from 'api/assetsUsage';
import { CopyIcon, LoadingOverlay } from 'components';
import { OffersContext } from 'contexts/offers';
import { useContext, useState } from 'react';
import styled from 'styled-components';

type AssetsPreviewProps = {
  assetsWithUsage: AssetWithUsage[];
  loading: boolean;
  onRemove: (assets: AssetWithUsage) => void;
};

/**
 * Show, filter and remove assets
 */
export const AssetsPreview = ({ assetsWithUsage, loading, onRemove }: AssetsPreviewProps) => {
  const [copiedId, setCopiedId] = useState('');
  const { offers } = useContext(OffersContext);

  /**
   * Manage offers badge and filtering of assets by offers
   * Did it by mistake (OUT OF SCOPE)
   * {offers.filter((o) => o.isSelected).length >= 1 ? renderBadgesRow() : ''}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // function renderFilterBadges() {
  //   return (
  //     <div className="row my-3">
  //       <div className="col">
  //         <h5 className="fw-semibold">Filter by Offers</h5>
  //         {offers
  //           .filter((o) => o.isSelected)
  //           .map((o, i) => (
  //             <button
  //               key={o.text + i}
  //               type="button"
  //               className={'btn m-1 btn-sm btn-outline-dark rounded-pill ' + (o.text === selectOffer?.text ? 'text-bg-dark' : '')}
  //               onClick={() => (o.text === selectOffer?.text ? setSelectOffer(undefined) : setSelectOffer(o))}
  //             >
  //               {o.text}
  //             </button>
  //           ))}
  //       </div>
  //     </div>
  //   );
  // }

  function copyFileId(usage: AssetUsage) {
    navigator.clipboard.writeText(usage.offer.fileId).then(() => {
      setCopiedId(usage.offer.fileId);
      setTimeout(() => {
        setCopiedId('');
      }, 1000);
    });
  }

  function renderAssetCard(asset: AssetWithUsage) {
    const offerList = asset.usages.map((usage) => {
      const offerName = offers.find((o) => o.id === usage.offer.id)?.text;
      return (
        <div key={usage.assetId} className="badge custom-badge">
          <a href={usage.uri} target="_blank" rel="noreferrer">
            {offerName}
          </a>
          <span className="separator">·</span>
          {usage.offer.fileId === copiedId ? (
            <button type="button" className="btn btn-no-border btn-outline-success" disabled>
              Copied!
            </button>
          ) : (
            <button type="button" className="btn btn-no-border" onClick={() => copyFileId(usage)}>
              {usage.offer.fileId} ID
              <CopyIcon />
            </button>
          )}
        </div>
      );
    });

    return (
      <AssetCardStyled className="row py-1">
        <div className="col-4 col-lg-3 image">
          {asset.files?.thumbnail?.url ? <img src={asset.files?.thumbnail?.url} alt={asset.name} /> : <></>}
        </div>
        <div className="col-8 col-lg-9 flex-grow-1">
          <div className="row">
            <p className="col-9 fw-semibold">
              {asset.name} {offerList.length ? <u> is already in :</u> : undefined}
            </p>
            <div className="col-3 text-end">
              <button
                onClick={() => onRemove(asset)}
                type="button"
                className="btn btn-no-border btn-outline-danger btn-sm ml-auto"
              >
                Remove
              </button>
            </div>
          </div>
          {offerList.length ? (
            // eslint-disable-next-line
            <div className={'offers-list'} style={{ margin: '-0.5rem' }}>{offerList}</div>
          ) : (
            <p className="custom-badge">No offers associated</p>
          )}
        </div>
      </AssetCardStyled>
    );
  }

  return (
    <LoadingOverlay loading={loading} description="Loading assets usages">
      <div className="list-group-overflowed">
        <div className="row my-3">
          <div className="col">
            <h5 className="fw-semibold">Selected Assets</h5>
            <div className="list-group list-group-flush g-4 mt-0">
              {Object.values(assetsWithUsage).map((asset, i) => (
                <div key={asset.databaseId + i} className="list-group-item">
                  {renderAssetCard(asset)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

const AssetCardStyled = styled.div`
  .image {
    position: relative;
    overflow: hidden;
    padding-bottom: 15%;
    background: var(--bs-gray-200);
    border-radius: 10px;
    img {
      position: absolute;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .custom-badge {
    font-size: 0.75rem;
    color: black !important;
    font-weight: 600;
    padding: 0.25rem;
    display: inline-flex;
    align-items: center;
    &:first-of-type {
    }
    a {
      text-decoration: none;
    }
    .separator {
      margin: 0 0.25em 0 0.5em;
    }
    .btn {
      font-weight: 700;
      font-size: 1em;
      padding: 0.25em;
      svg {
        margin-left: 0.25rem;
      }
    }
  }
`;
