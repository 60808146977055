type ModalFooterProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const ModalFooter = ({ children }: ModalFooterProps) => {
  return (
    <div className="row border-top py-3">
      <div className="col">{children}</div>
    </div>
  );
};
