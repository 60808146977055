import styled from 'styled-components';

export const BynderStyled = styled.div`
  position: fixed;
  top: 0rem;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;

  div.headerContainer + div:nth-child(2) {
    place-content: center;
    height: calc(100% - 4rem) !important;
  }
`;

export const BynderModalStyled = styled.div`
  position: relative;
  overflow: auto;
  height: 80vh;

  div:first-child {
    place-content: center;
  }
`;

export const BynderModalStyledContainer = styled.div`
  .modal-container {
    width: auto !important;
    min-width: 1232px !important;
    max-width: 1320px !important;
  }
`;
