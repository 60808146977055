import styled from 'styled-components';

type LoadingOverlayProps = {
  loading: boolean;
  description?: string;
  children: string | JSX.Element | JSX.Element[];
};

export const LoadingOverlay = ({ loading, children, description = 'Loading...' }: LoadingOverlayProps) => {
  return loading ? (
    <LoadingOverlayStyled>
      <div className="d-flex align-items-center justify-content-center">
        <strong>{description}</strong>
        <div className="spinner-border" role="status" aria-hidden="true"></div>
      </div>
    </LoadingOverlayStyled>
  ) : (
    <>{children}</>
  );
};

const LoadingOverlayStyled = styled.div`
  position: relative;
  height: 50vh;
  .justify-content-center {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    margin: auto;
    background: var(--bs-white);
  }
  .spinner-border {
    margin-left: 25%;
  }
`;

export const CardLoadingOverlay = () => {
  return (
    <CardLoadingOverlayStyled>
      <div className="spinner-grow text-secondary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </CardLoadingOverlayStyled>
  );
};

const CardLoadingOverlayStyled = styled.div`
  position: absolute;
  background-color: #5f5f5f32;
  top: 0%;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;
