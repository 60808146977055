import { Offer } from 'api/offers';
import { createContext, useReducer } from 'react';

type OfferReducerDataToogle = { offer: Offer; isSelected: boolean };
type OfferReducerAction = { type: string; data?: OfferReducerDataToogle | Offer[] };
type OfferReducer = (prevState: Offer[], action: OfferReducerAction) => Offer[];
type OfferDispatcher = React.Dispatch<OfferReducerAction>;

const initialOffers: Offer[] = [];

export function offerReducer(prevState: Offer[], action: OfferReducerAction) {
  switch (action.type) {
    case 'setAll': {
      return [...(action.data as Offer[])];
    }
    case 'toogleOne': {
      const data = action.data as OfferReducerDataToogle;
      const offer = prevState.find((o) => o.text === data.offer.text);
      if (offer) {
        offer.isSelected = data.isSelected;
      }
      return [...prevState];
    }
    case 'reset': {
      prevState.forEach((offer) => (offer.isSelected = false));
      return [...prevState];
    }
    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

/**
 * Sharing offers state between multiple subcomponents
 */
export const OffersContext = createContext({} as { offers: Offer[]; dispatch: OfferDispatcher });

/**
 * Wrapper to provide context to a component
 */
export function OffersProviders({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(offerReducer as OfferReducer, initialOffers);
  return (
    <OffersContext.Provider
      value={
        {
          offers: state,
          dispatch,
        } as { offers: Offer[]; dispatch: OfferDispatcher }
      }
    >
      {children}
    </OffersContext.Provider>
  );
}
