import { useState } from 'react';
import styled from 'styled-components';
import { TabProps } from './tab';

type TabsProps = {
  children: React.ReactElement<TabProps>[];
};

/**
 * Dynamic Tabs generator
 *
 * <Tabs>
 *   <Tab id="1" title="Hello">...</Tab>
 *   <Tab id="2" title="World" disabled />
 * </Tabs>
 */
export const Tabs = ({ children }: TabsProps) => {
  // First tab selected by default
  const [selectedTab, setSelectedTab] = useState(children[0].props.id);

  function renderTabBtn() {
    return children.map((child, i) => (
      <li className="nav-item" key={child.props.id + i}>
        {child.props.disabled ? (
          <button type="button" className="nav-link" disabled>
            {child.props.title}
          </button>
        ) : (
          <button
            type="button"
            className={'nav-link ' + (selectedTab === child.props.id ? 'active' : '')}
            onClick={() => setSelectedTab(child.props.id)}
            data-testid={'tab-' + (i + 1)}
          >
            {child.props.title}
            {child.props.chips && <span className="badge rounded-pill text-bg-primary">{child.props.chips}</span>}
          </button>
        )}
      </li>
    ));
  }

  function renderTabContent() {
    return children
      .filter((child) => selectedTab === child.props.id)
      .map((child) => <section key={child.props.id}>{child}</section>);
  }

  return (
    <TabsStyled className="row">
      <ul className="col nav nav-tabs">{renderTabBtn()}</ul>
      {renderTabContent()}
    </TabsStyled>
  );
};

const TabsStyled = styled.div`
  .nav-tabs {
    border-bottom: 2px solid var(--bs-gray-300);
  }
  .nav-tabs .nav-link {
    font-size: 1.1rem;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    border: none;
  }
  .nav-tabs .nav-link.active {
    border-bottom: 2px solid black;
  }
  .badge {
    font-size: 0.7rem;
    padding: 0.25rem 0.75rem;
    margin-left: 0.5rem;
    color: white !important;
  }
`;
