import styled from 'styled-components';

const ModalContentStyled = styled.div`
  position: relative;
  overflow: hidden;
`;

type ModalContentProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const ModalContent = ({ children }: ModalContentProps) => {
  return <ModalContentStyled className="px-3">{children}</ModalContentStyled>;
};
