import { Offer } from './offers';
import { AssetWithUsage } from './assetsUsage';

export const removeDuplicatesOffers = (offers: Offer[], asset: AssetWithUsage) => {
  const assetIds: string[] = [];
  asset.usages.forEach((usage) => {
    assetIds.push(usage.offer.id);
  });
  return offers.filter((offer) => !assetIds.includes(offer.id));
};
