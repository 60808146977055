import { Asset, AssetWithUsage, getAssetUsage } from 'api/assetsUsage';
import { getOffersList } from 'api/offers';
import { Modal, ModalContent, ModalHeader, Tab, Tabs } from 'components';
import { OffersContext } from 'contexts/offers';
import * as React from 'react';
import { AssetsPreview } from './assetsPreview';
import { TuneOffersSelector } from './tuneOffersSelector';

type SendCreativeModalProps = {
  closeModal: () => void;
  assets: Asset[];
};

/**
 * Modal to manage where to send assets to offers
 */
export const SendCreativeModal = ({ closeModal, assets }: SendCreativeModalProps) => {
  const { dispatch: dispatchContext } = React.useContext(OffersContext);
  const [assetsWithUsage, setAssetsWithUsage] = React.useState<AssetWithUsage[]>([]);
  const [loadingOffers, setLoadingOffers] = React.useState<boolean>(true);
  const [loadingAssets, setLoadingAssets] = React.useState<boolean>(true);

  React.useEffect(() => {
    getOffersList().then((offers) => {
      dispatchContext({ type: 'setAll', data: offers });
      setLoadingOffers(false);
    });
    getAssetUsage(assets).then((assetsWithUsage) => {
      setAssetsWithUsage(assetsWithUsage);
      setLoadingAssets(false);
    });
  }, [assets, dispatchContext]);

  function removeSelectedAssets(asset: AssetWithUsage) {
    const filtered = assetsWithUsage.filter((a) => a.id !== asset.id);
    setAssetsWithUsage(filtered);
  }

  return (
    <Modal>
      <ModalHeader title="Send Creative" closeCallback={closeModal} />
      <ModalContent>
        <Tabs>
          <Tab id="tune" title="Tune" chips="">
            <TuneOffersSelector assetsWithUsage={assetsWithUsage} loading={loadingOffers} closeModal={closeModal} />
          </Tab>
          <Tab id="adKernel" title="AdKernel" disabled />
          <Tab id="assets" title="Assets" chips={`${Object.keys(assetsWithUsage).length}`}>
            <AssetsPreview
              assetsWithUsage={assetsWithUsage}
              loading={loadingAssets || loadingOffers}
              onRemove={removeSelectedAssets}
            />
          </Tab>
        </Tabs>
      </ModalContent>
    </Modal>
  );
};
