import { AssetWithUsage } from 'api/assetsUsage';
import { uploadBanners } from 'api/banner';
import { Offer } from 'api/offers';
import { LoadingOverlay, ModalFooter, SelectableOfferList, TrashIcon } from 'components';
import { AlertOverlay, AlertOverlayProps } from 'components/alert-overlay';
import { OffersContext } from 'contexts/offers';
import { useContext, useState } from 'react';
import styled from 'styled-components';

type TuneOffersSelectorProps = {
  assetsWithUsage: AssetWithUsage[];
  loading: boolean;
  closeModal: () => void;
};

/**
 * Section that manage tune offers
 */
export const TuneOffersSelector = ({ loading, assetsWithUsage, closeModal }: TuneOffersSelectorProps) => {
  const [isPrivate, setIsPrivate] = useState(false);
  const [isAvoidDuplicates, setIsAvoidDuplicates] = useState(false);
  const [alert, setAlert] = useState({ type: undefined, text: undefined } as AlertOverlayProps);
  const [sending, setSending] = useState(false);
  const { offers, dispatch } = useContext(OffersContext);

  /**
   * Call when user remove or add an offer
   */
  function onSelect(offer: Offer, isSelected: boolean) {
    dispatch({ type: 'toogleOne', data: { offer, isSelected } });
  }

  const numberOfAssetsInOffert = (offer: Offer): number => {
    let numberOfAssets = 0;
    assetsWithUsage.forEach((asset) => {
      const usagesId = Array.from(
        new Set(
          asset.usages.map((usage) => {
            return usage.offer.id;
          }),
        ),
      );
      usagesId.forEach((usageId) => {
        if (usageId === offer.id) numberOfAssets++;
      });
    });
    return numberOfAssets;
  };

  function renderSelectedOffers() {
    return offers
      .filter((o) => o.isSelected)
      .map((offer, i) => {
        const AssetsInOffert = numberOfAssetsInOffert(offer);
        return (
          <li key={offer.text + i} className="list-group-item bg-gray-100">
            <div className="me-auto">
              <div className="fw-medium">{offer.text}</div>
              {AssetsInOffert > 0 && (
                <span className="fw-semibold text-primary" data-testid={'assetDuplicatesButton'}>
                  {AssetsInOffert} assets already present in this offer
                </span>
              )}
            </div>
            <button
              type="button"
              data-testid={'unselect-' + offer.text}
              onClick={() => onSelect(offer, false)}
              className="btn btn-sm"
              style={{ fill: 'var(--bs-red)' }}
            >
              <TrashIcon />
            </button>
          </li>
        );
      });
  }

  function sendToTune() {
    setSending(true);
    uploadBanners(assetsWithUsage, offers, isPrivate, isAvoidDuplicates)
      .then((msg) => {
        setAlert({ type: 'primary', text: msg, close: closeModal });
      })
      .catch((msg) => {
        setAlert({ type: 'danger', text: msg, close: closeModal });
      })
      .finally(() => {
        setSending(false);
      });
  }

  const selectedOffers = renderSelectedOffers();

  return (
    <LoadingOverlay loading={loading} description="Loading offers from Tune">
      <TuneOffersSelectorStyled>
        <AlertOverlay text={alert.text} type={alert.type} close={closeModal} />
        <div className="content">
          <div className="row min-h-60">
            <div className="col-6">
              <div className="mt-3">
                <SelectableOfferList offers={offers} onSelect={onSelect} />
              </div>
            </div>
            <div className="col-6">
              <div className="selected-offer-list my-3 p-3 bg-gray-100 rounded">
                <h5 className="fw-semibold mb-3">Selected offer</h5>
                <ul className="list-group list-group-overflowed">
                  {selectedOffers.length > 0 ? (
                    selectedOffers
                  ) : (
                    <div className="alert text-secondary" role="alert">
                      No offer selected
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </div>
          <ModalFooter>
            <div className="row">
              <div className="col d-flex align-items-center justify-content-start">
                <div className="me-5 form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isPrivate}
                    id="private"
                    data-testid={'private-checkbox'}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="private">
                    Private
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isAvoidDuplicates}
                    id="avoidDuplicates"
                    data-testid={'avoidDuplicates-checkbox'}
                    onChange={(e) => setIsAvoidDuplicates(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="avoidDuplicates">
                    Avoid duplicates
                  </label>
                </div>
              </div>
              <div className="col-auto">
                <button
                  type="button"
                  data-testid={'btn-send'}
                  className="btn btn-primary"
                  disabled={selectedOffers.length === 0 || sending}
                  onClick={sendToTune}
                >
                  Send to Tune
                </button>
              </div>
            </div>
          </ModalFooter>
        </div>
      </TuneOffersSelectorStyled>
    </LoadingOverlay>
  );
};

const TuneOffersSelectorStyled = styled.div`
  .selected-offer-list {
    margin: 1rem 0;
    height: calc(100% - 2rem);
    .list-group-item {
      display: flex;
      align-items: center;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid black;
    }
  }
`;
