import axios from 'axios';
import sortBy from 'lodash/sortBy';
import { CreativesAdminApi, CreativesAdminBaseAPIUrl } from '../common/api-routes';

interface APIReponseData {
  [index: number]: {
    advertiserId: string;
    advertiserName: string;
    name: string;
  };
}

export interface Offer {
  id: string;
  isSelected: boolean;
  name: string;
  text: string;
  advertiserId: string;
}

let offersCache: Offer[] = [];

/**
 * Return all offers in creadmin bd
 */
export const getOffersList = async (): Promise<Offer[]> => {
  if (offersCache.length >= 1) {
    return offersCache;
  }
  return await axios
    .get(CreativesAdminBaseAPIUrl + CreativesAdminApi.offers)
    .then(({ data }: { data: APIReponseData }) => {
      const offers: Offer[] = Object.entries(data).map((value) => ({
        id: value[0],
        isSelected: false,
        text: `${value[1].name} (${value[0]})`,
        name: value[1].advertiserId,
        advertiserId: value[1].advertiserId,
      }));
      offersCache = offers;
      return sortBy(offers, 'text');
    })
    .catch((e) => {
      console.error('Error from CAS API', e);
      return [];
    });
};
