export const FilterIcon = () => {
  return (
    <svg
      className="icon"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      style={{
        width: '1.125rem',
        flex: 'none',
      }}
    >
      <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
    </svg>
  );
};

export const CloseIcon = () => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
      <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
    </svg>
  );
};

export const TrashIcon = () => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16}>
      <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
    </svg>
  );
};

export const CopyIcon = () => {
  return (
    <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={12} height={12}>
      <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      className="icon"
      data-testid="check-icon"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '1.5rem' }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5663 10.5059L20.8382 9.7777C20.5059 9.44436 20.3236 9.00061 20.3236 8.53082V7.48916C20.3236 5.93811 19.0611 4.67666 17.5111 4.67666H16.4674C15.9955 4.67666 15.5528 4.49436 15.2215 4.16311L14.4809 3.42353C13.3799 2.33186 11.5965 2.33707 10.5038 3.43499L9.77776 4.16311C9.44338 4.49541 9.00067 4.6777 8.52984 4.6777H7.48713C5.95484 4.67874 4.70484 5.91207 4.67672 7.43916C4.67567 7.45582 4.67463 7.47249 4.67463 7.4902V8.52874C4.67463 8.99957 4.49234 9.44228 4.16005 9.77457L3.42255 10.5131C3.42151 10.5163 3.41838 10.5173 3.4163 10.5194C2.32776 11.6215 2.33713 13.4048 3.43401 14.4913L4.16213 15.2215C4.49338 15.5538 4.67672 15.9954 4.67672 16.4663V17.5131C4.67672 19.0631 5.93713 20.3246 7.48713 20.3246H8.52776C8.99963 20.3256 9.44234 20.5079 9.77359 20.8381L10.5163 21.5788C11.0455 22.1048 11.7476 22.3944 12.4944 22.3944H12.5069C13.258 22.3913 13.9622 22.0954 14.4892 21.5652L15.2194 20.8361C15.5476 20.509 16.0017 20.3215 16.4653 20.3215H17.5132C19.0601 20.3215 20.3215 19.0621 20.3247 17.5131V16.4684C20.3247 15.9986 20.5069 15.5559 20.8372 15.2236L21.5778 14.4829C22.6715 13.3829 22.6653 11.5986 21.5663 10.5059Z"
        fill="url(#paint0_linear_263_1622)"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.233 10.9979L11.9903 15.2427C11.8434 15.3896 11.6444 15.4719 11.4372 15.4719C11.2299 15.4719 11.0309 15.3896 10.8851 15.2427L8.82565 13.1802C8.52148 12.874 8.52148 12.3792 8.82669 12.074C9.13294 11.7698 9.62669 11.7709 9.9319 12.075L11.4382 13.5844L15.1278 9.89272C15.433 9.58752 15.9278 9.58752 16.233 9.89272C16.5382 10.1979 16.5382 10.6927 16.233 10.9979Z"
        fill="white"
      ></path>
      <defs>
        <linearGradient id="paint0_linear_263_1622" x1="12.5" y1="2.60815" x2="12.5" y2="22.3944" gradientUnits="userSpaceOnUse">
          <stop stopColor="#221EA7"></stop>
          <stop offset="0.0001" stopColor="#231FA9"></stop>
          <stop offset="1" stopColor="#6762FF"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const OpenIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ height: '1.5rem' }}>
      <title>chevron-down</title>
      <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
    </svg>
  );
};
