export const CreativesAdminBaseAPIUrl = process.env.REACT_APP_CREATIVES_ADMIN_API;
export const BynderBaseAPIUrl = process.env.REACT_APP_BYNDER_API;
export const CatalogAPIUrl = process.env.REACT_APP_RAZZLE_CATALOG_API;
export const PerformerAPIUrl = process.env.REACT_APP_RAZZLE_PERFORMER_API;
export const VideoAPIUrl = process.env.REACT_APP_RAZZLE_VIDEO_API;

export enum CreativesAdminApi {
  upload = '/banner/upload',
  offers = '/offers/list',
  report = '/asset/usage',
  adminAttributes = '/performer/adminAttributes',
  catalog = '/catalog',
  assets = '/asset/assets-info',
  stats = '/performer/assets/stats',
}

export enum PerformerApi {
  performer = '/performer',
  pn = '/pn',
}

export enum VideoApi {
  video = '/video',
  videos = '/videos',
  count = '/videos/count',
}

export enum BynderApi {
  currentUser = '/v4/currentUser/',
}

export enum ClHostname {
  jerkmate = 'cl.jerkmate',
  // test = 'cl.content_admin_tmp',
}
