import { AssetStats } from 'api/assetsStats';
import { BynderAsset } from 'api/bynderAssets';
import { createContext, useReducer } from 'react';

export interface NetworkCacheReducerState {
  assetsStatsCache: { [key: string]: AssetStats };
  assetsBynderCache: { [key: string]: BynderAsset };
}

type AddToCacheAction = {
  type: 'addToCache';
  data: { assetsStatsCache?: AssetStats[]; assetsBynderCache?: BynderAsset[] };
};

export type NetworkCacheReducerAction = AddToCacheAction;

export type NetworkCacheDispatcher = React.Dispatch<NetworkCacheReducerAction>;

const initialState: NetworkCacheReducerState = {
  assetsStatsCache: {},
  assetsBynderCache: {},
};

export function networkCacheReducer(
  prevState: NetworkCacheReducerState,
  action: NetworkCacheReducerAction,
): NetworkCacheReducerState {
  switch (action.type) {
    case 'addToCache': {
      action.data.assetsBynderCache?.forEach((ab) => {
        prevState.assetsBynderCache[ab.assetId] = ab;
      });
      action.data.assetsStatsCache?.forEach((ab) => {
        prevState.assetsStatsCache[ab.assetId] = ab;
      });
      return {
        ...prevState,
      };
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}

export const NetworkCacheContext = createContext({} as { state: NetworkCacheReducerState; dispatch: NetworkCacheDispatcher });

export function NetworkCacheProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(networkCacheReducer, initialState);
  return (
    <NetworkCacheContext.Provider
      value={
        {
          state,
          dispatch,
        } as { state: NetworkCacheReducerState; dispatch: NetworkCacheDispatcher }
      }
    >
      {children}
    </NetworkCacheContext.Provider>
  );
}
