import { Toast, ToastContext } from 'contexts/toasts';
import { FC, useContext, useEffect } from 'react';

const ToastComponent: FC<{ toast: Toast }> = ({ toast }) => {
  const { dispatch } = useContext(ToastContext);
  const colorSchema =
    toast.status === 'success' ? 'text-white bg-success' : toast.status === 'error' ? 'text-white bg-danger' : '';

  const remove = () => {
    dispatch({
      type: 'removeToast',
      data: {
        id: toast.id,
      },
    });
  };

  return (
    <div id="liveToast" className="toast show" role="alert" aria-live="assertive" aria-atomic="true">
      <div className={`toast-header ${colorSchema}`}>
        <strong className="me-auto">{toast.title}</strong>
        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={remove}></button>
      </div>
      <div className="toast-body">{toast.message}</div>
    </div>
  );
};

export const Toasts: FC = () => {
  const { state, dispatch } = useContext(ToastContext);

  /* istanbul ignore next */
  useEffect(() => {
    if (state.toasts[0]) {
      setTimeout(() => {
        dispatch({
          type: 'removeToast',
          data: {
            id: state.toasts[0].id,
          },
        });
      }, 4000);
    }
  }, [dispatch, state]);

  return (
    <div className="toast-container position-fixed bottom-0 end-0 p-3">
      {state.toasts.map((toast) => (
        <ToastComponent key={toast.id} toast={toast} />
      ))}
    </div>
  );
};
