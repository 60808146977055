import styled from 'styled-components';
import { FilterIcon } from '../icon';

type InputWrapperProps = {
  name: string;
  placeholder: string;
  handleOnChange: (index: string) => void;
};

export const InputWrapper = ({ name, placeholder, handleOnChange }: InputWrapperProps) => {
  return (
    <InputWrapperStyled className="input-group input-group-lg mb-3">
      <span className="input-group-text" id="filter-icon">
        <FilterIcon />
      </span>
      <input
        type="text"
        className="form-control"
        name={name}
        placeholder={placeholder}
        aria-label={placeholder}
        aria-describedby="filter-icon"
        onChange={(e) => handleOnChange(e.target.value)}
        data-testid={'input-filter'}
      />
    </InputWrapperStyled>
  );
};

const InputWrapperStyled = styled.div`
  .input-group-text {
    border: 2px solid #000000;
    border-right: none;
    background: white;
  }
  .form-control {
    border: 2px solid #000000;
    border-left: none;
  }
`;
