import styled from 'styled-components';

const OverlayStyled = styled.div`
  position: fixed;
  z-index: 50;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalStyled = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  min-height: 60vh;
  width: 80%;
  max-width: 1088px;
  border-radius: 10px;
`;

type ModalProps = {
  children: string | JSX.Element | JSX.Element[];
};

export const Modal = ({ children }: ModalProps) => {
  return (
    <OverlayStyled>
      <ModalStyled className={'modal-container'}>
        <div className="container d-flex flex-column">{children}</div>
      </ModalStyled>
    </OverlayStyled>
  );
};
