import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { createContext, useReducer } from 'react';
import { Model } from 'types/catalog.entity';

export interface ModelReducerState {
  // Necessary to compare changes
  original: Model;
  // Will be update in the reducer
  updated: Model;
  // Track difference between original & updated
  hasChange: boolean;
}

type InitStateAction = {
  type: 'initState';
  data: Model;
};

type UpdateScoreAction = {
  type: 'updateScore';
  data: {
    score: number;
  };
};

export type ModelReducerAction = InitStateAction | UpdateScoreAction;

export type ModelDispatcher = React.Dispatch<ModelReducerAction>;

const initialState: ModelReducerState = {
  original: undefined!,
  updated: undefined!,
  hasChange: false,
};

const stateUpdate = (state: ModelReducerState): ModelReducerState => {
  return {
    ...state,
    hasChange: !isEqual(state.original, state.updated),
  };
};

export function modelReducer(prevState: ModelReducerState, action: ModelReducerAction): ModelReducerState {
  switch (action.type) {
    case 'initState': {
      const model = (action as InitStateAction).data;
      return stateUpdate({
        ...prevState,
        original: cloneDeep(model),
        updated: model,
      });
    }
    case 'updateScore': {
      prevState.updated.score = action.data.score;
      return stateUpdate({
        ...prevState,
      });
    }
    default: {
      throw Error('Unknown action: ' + JSON.stringify(action));
    }
  }
}

/**
 * Sharing offers state between multiple subcomponents
 */
export const ModelContext = createContext({} as { state: ModelReducerState; dispatch: ModelDispatcher });

/**
 * Wrapper to provide context to a component
 */
export function ModelProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(modelReducer, initialState);
  return (
    <ModelContext.Provider
      value={
        {
          state,
          dispatch,
        } as { state: ModelReducerState; dispatch: ModelDispatcher }
      }
    >
      {children}
    </ModelContext.Provider>
  );
}
