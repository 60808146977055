import axios from 'axios';
import { CreativesAdminApi, CreativesAdminBaseAPIUrl } from 'common/api-routes';
import { AssetWithUsage } from './assetsUsage';
import { Offer } from './offers';
import { getCurrentUser } from './user';
import { removeDuplicatesOffers } from './utils';

interface UploadBannerParams {
  assets: { id: string }[];
  offers: string[];
  private: boolean;
  user: { email: string };
}

export const sendSuccessMessage = () => {
  return 'The request was sent successfully. An email will be sent when the upload is complete.';
};

export const sendErrorMessage = (e: Error) => {
  console.error('Error from when uploading the banner', e);
  return 'Error from when uploading the banner';
};

export const uploadBanners = async (
  assets: AssetWithUsage[],
  offers: Offer[],
  isPrivate: boolean,
  isAvoidDuplicates: boolean,
): Promise<string | undefined> => {
  const offersSelected = offers.filter((o) => o.isSelected);
  if (isAvoidDuplicates) {
    const multipleUploadsBanners = assets.map((asset) => {
      return {
        assets: [asset],
        offers: removeDuplicatesOffers(offersSelected, asset),
        isPrivate,
      };
    });
    const promises = [];
    for (let i = 0; i < multipleUploadsBanners.length; i++) {
      if (multipleUploadsBanners[i]['offers'].length > 0) {
        promises.push(
          uploadBanner(
            multipleUploadsBanners[i]['assets'],
            multipleUploadsBanners[i]['offers'],
            multipleUploadsBanners[i]['isPrivate'],
          ),
        );
      }
    }

    return Promise.all(promises)
      .then(() => {
        return sendSuccessMessage();
      })
      .catch((e) => {
        return sendErrorMessage(e);
      });
  } else {
    return uploadBanner(assets, offersSelected, isPrivate);
  }
};
/**
 * Upload associtation between assets and offers
 */
const uploadBanner = async (assets: AssetWithUsage[], offers: Offer[], isPrivate: boolean): Promise<string | undefined> => {
  return await getCurrentUser()
    .then((user) => {
      const params: UploadBannerParams = {
        assets: assets.map((a) => ({ id: a.databaseId })),
        offers: offers.map((o) => o.id),
        private: isPrivate,
        user,
      };
      return axios.post(CreativesAdminBaseAPIUrl + CreativesAdminApi.upload, params);
    })
    .then(() => {
      return sendSuccessMessage();
    })
    .catch((e) => {
      return sendErrorMessage(e);
    });
};
