import { CloseIcon } from 'components/icon';
import styled from 'styled-components';

const ModalHeaderStyled = styled.div`
  button.btn.close {
    border-radius: 10px;
    line-height: 1;
    border: 2px solid var(--bs-body-color);
    fill: var(--bs-body-color);
  }
  button.btn.close:hover {
    fill: var(--bs-body-bg);
    background: var(--bs-body-color);
  }
`;

type ModalHeaderProps = {
  title: string;
  closeCallback: () => void;
};

export const ModalHeader = ({ title, closeCallback }: ModalHeaderProps) => {
  return (
    <ModalHeaderStyled className="row p-3">
      <div className="col">
        <h4>{title}</h4>
      </div>
      <div className="col-sm-auto">
        <button type="button" data-testid="close" className="btn close p-1" onClick={() => closeCallback()}>
          <CloseIcon />
        </button>
      </div>
    </ModalHeaderStyled>
  );
};
