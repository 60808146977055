import axios from 'axios';
import { CreativesAdminApi } from '../common/api-routes';

export interface Asset {
  id: string;
  databaseId: string;
  name: string;
  files: { thumbnail: { url: string } };
}
export interface AssetUsage {
  assetId: string;
  integration: {
    description: string;
    id: string;
  };
  offer: {
    id: string;
    fileId: string;
    private: boolean;
  };
  timestamp: string;
  uri: string;
  user: { email: string };
}

export interface AssetWithUsage extends Asset {
  usages: AssetUsage[];
}

function makeAssetWithUsage(assets: Asset[], usages: AssetUsage[]): AssetWithUsage[] {
  return assets.map((asset) => {
    const assetUsages = usages.filter((usage) => {
      return asset.databaseId.toLowerCase().replaceAll('-', '') === usage.assetId.toLowerCase().replaceAll('-', '');
    });
    return { ...asset, usages: assetUsages } as AssetWithUsage;
  });
}

/**
 * Assets usage tell which assets are associated with which offers
 */
export const getAssetUsage = async (assets: Asset[]): Promise<AssetWithUsage[]> => {
  const params = { ids: assets.map((a) => a.databaseId) };
  return await axios
    .post(process.env.REACT_APP_CREATIVES_ADMIN_API + CreativesAdminApi.report, params)
    .then(({ data }: { data: AssetUsage[] }) => makeAssetWithUsage(assets, data))
    .catch((e) => {
      console.error('Error from CAS API', e);
      return [];
    });
};
