import styled from 'styled-components';

export type AlertOverlayProps = {
  type: 'danger' | 'primary' | undefined;
  text: string | undefined;
  close: () => void;
};

export const AlertOverlay = ({ type, text, close }: AlertOverlayProps) => {
  return text ? (
    <AlertOverlayStyled>
      <div className={`alert alert-send alert-${type} alert-dismissible`} role="alert">
        {text}
        <button
          type="button"
          data-testid="close-alert"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => close()}
        ></button>
      </div>
    </AlertOverlayStyled>
  ) : (
    <></>
  );
};

const AlertOverlayStyled = styled.div`
  position: absolute;
  z-index: 10;
  width: calc(100% + 0rem);
  height: 100%;
  backdrop-filter: blur(10px);
  left: 50%;
  transform: translate(-50%, 0);
  .alert-send {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
