/* istanbul ignore file */
import { CompactView } from '@bynder/compact-view';
import { Asset } from 'api/assetsUsage';
import { useState } from 'react';
import { SendCreativeModal } from '../sendCreativeModal/modal';
import { BynderStyled } from './bynder.styled';

const assetFieldSelection = `
  databaseId
  name
`;

export const BynderView = () => {
  const [selectedAssets, setSelectedAssets] = useState<Asset[]>([]);
  const [showCreativeModal, setShowCreativeModal] = useState(false);

  const onSuccess = (assets: unknown[]) => {
    setSelectedAssets(Object.values(assets) as Asset[]);

    setShowCreativeModal(true);
  };

  return (
    <>
      <BynderStyled>
        <CompactView language="en_US" onSuccess={onSuccess} assetFieldSelection={assetFieldSelection} mode={'MultiSelect'} />
      </BynderStyled>
      {showCreativeModal ? <SendCreativeModal closeModal={() => setShowCreativeModal(false)} assets={selectedAssets} /> : null}
    </>
  );
};
