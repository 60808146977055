import React from 'react';
import styled from 'styled-components';

const TabStyled = styled.div``;

export type TabProps = {
  id: string;
  title: string;
  chips?: string;
  disabled?: boolean;
  children?: string | JSX.Element | JSX.Element[];
};

export class Tab extends React.Component<TabProps, never> {
  render() {
    return <TabStyled>{this.props.children}</TabStyled>;
  }
}
