import { Offer } from 'api/offers';
import { InputWrapper } from 'components';
import { Properties } from 'csstype';
import { useState } from 'react';
import { VariableSizeList } from 'react-window';
import styled from 'styled-components';

type SelectableOfferListProps = {
  offers: Offer[];
  onSelect: (offer: Offer, isSelected: boolean) => void;
};

/**
 * SelectableOfferList display a large amount of offers
 * VariableSizeList is set to 800px but CSS override it to 100%
 * This make the list dynamic in term of layout
 */
export const SelectableOfferList = ({ offers, onSelect }: SelectableOfferListProps) => {
  const [filter, setFilter] = useState('');
  const selectedOffers = offers.filter((o) => o.text.toLowerCase().includes(filter.toLowerCase()));

  const Row = ({ index, style }: { index: number; style: Properties<string | number, string> }) => {
    const current = selectedOffers[index];
    return (
      <li style={style} className="list-group-item">
        <input
          className="form-check-input me-2"
          type="checkbox"
          checked={current.isSelected}
          onChange={(e) => onSelect(current, e.target.checked)}
          id={current.text}
          data-testid={'check-' + current.text}
        />
        <label className="form-check-label w-100 p-3 ps-1 pe-auto" htmlFor={current.text}>
          {current.text}
        </label>
      </li>
    );
  };

  return (
    <SelectableOfferListStyled>
      <InputWrapper name="search-offer" placeholder="Seach offer..." handleOnChange={(v) => setFilter(v)} />
      <VariableSizeList
        className="list-group-overflowed"
        height={800}
        itemCount={selectedOffers.length}
        itemSize={() => 54}
        width={'auto'}
      >
        {Row}
      </VariableSizeList>
    </SelectableOfferListStyled>
  );
};

const SelectableOfferListStyled = styled.div`
  .list-group {
    height: 100% !important;
    margin-bottom: 1px;
  }
  .list-group-item {
    border: 1px solid transparent !important;
    border-radius: 5px;
    display: flex;
    height: 54px;
    align-items: center;
    padding: 0;
    &:hover {
      background: var(--bs-gray-100);
    }
  }
`;
