import { createContext, useReducer } from 'react';

export interface Toast {
  title: string;
  message: string;
  id: number;
  status: 'success' | 'error' | undefined;
}

type AddAction = {
  type: 'addToast';
  data: {
    title: string;
    message: string;
    status: 'success' | 'error' | undefined;
  };
};

type RemoveAction = {
  type: 'removeToast';
  data: {
    id: number;
  };
};

type ReducerAction = AddAction | RemoveAction;
export type ReducerState = {
  toasts: Toast[];
  counter: number;
};

export type ToastDispatcher = React.Dispatch<ReducerAction>;

const initialState: ReducerState = {
  toasts: [],
  counter: 0,
};

export function toastReducer(prevState: ReducerState, action: ReducerAction): ReducerState {
  switch (action.type) {
    case 'addToast': {
      return {
        ...prevState,
        toasts: [...prevState.toasts, { ...action.data, id: prevState.counter, status: action.data.status }],
        counter: prevState.counter + 1,
      };
    }
    case 'removeToast': {
      return {
        ...prevState,
        toasts: [...prevState.toasts.filter((t) => t.id !== action.data.id)],
      };
    }
    default: {
      throw Error('Unknown action: ' + action);
    }
  }
}

/**
 * Sharing offers state between multiple subcomponents
 */
export const ToastContext = createContext({} as { state: ReducerState; dispatch: ToastDispatcher });

/**
 * Wrapper to provide context to a component
 */
export function ToastProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
  const [state, dispatch] = useReducer(toastReducer, initialState);
  return (
    <ToastContext.Provider
      value={
        {
          state,
          dispatch,
        } as { state: ReducerState; dispatch: ToastDispatcher }
      }
    >
      {children}
    </ToastContext.Provider>
  );
}
